import React, {ChangeEvent, FunctionComponent, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button, IconButton,
    TextField,
    Typography,
} from "@sber-friend/flamingo-core";
import style from "./HardwareToken.module.scss";
import SidePanel from "../../../components/SidePanel/SidePanel";
import {useEffectOnce, useLocalStorage} from "usehooks-ts";
import {ReactComponent as SberIdIcon} from "../../../assets/img/icons/sberId.svg";
import {ReactComponent as GosUslugiIcon} from "../../../assets/img/icons/gosuslugi.svg";
import SuccessAlert from '../../../components/SuccessAlert/SuccessAlert';
import {
    useActivateTokenMutation,
    useGetMultiFactorUrlMutation, useGetUserRoleQuery,
    useMultiFactorVerifyMutation
} from "../../../api/devices";
import {routesPath} from "../../../routes/const";
import {useNavigate, useSearchParams} from "react-router-dom";
import tokenFrontImg from "../../../assets/img/Devices/tokenFront.png"
import tokenBackImg from "../../../assets/img/Devices/tokenBack.png"
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {
    CloseCircleIcon,
    DoneInCircleLineIcon,
    EyeIcon,
    EyeOffIcon,
} from '@sber-friend/flamingo-icons';
import Snackbar from '@sber-friend/flamingo-core/Snackbar';


interface OwnProps {
    openSidePanel: boolean,
    handleOpenSidePanel: () => void,
    handleCloseSidePanel: () => void
}

type Props = OwnProps;

const HardwareTokenSigma: FunctionComponent<Props> = ({openSidePanel, handleCloseSidePanel, handleOpenSidePanel}) => {

    const ErrorMultifactorServer = "exc=602";
    const alertEsiaAutoOpen = useRef<ReturnType<typeof setTimeout>>();


    const navigate = useNavigate();
    const [searchParams] = useSearchParams();


    // --- запросы

    const [getMultiFactorUrl] = useGetMultiFactorUrlMutation()


    const [multiFactorVerify, {
        data: multiFactorVerifyData,
        error: multiFactorVerifyError,
        reset: multiFactorVerifyReset
    }] = useMultiFactorVerifyMutation()

    const [fetchActivateToken, {
        isLoading: loadingActivateToken,
        error: activateTokenError,
        data: activateTokenData
    }] = useActivateTokenMutation()

    const {data: userRole} = useGetUserRoleQuery(undefined);


    // ---

    const [step2Passed, setStep2Passed] = useState(false);


    const [pin1codeField, setPin1codeField] = useState("");
    const [showPin1codeField, setShowPin1codeField] = useState(false);

    const [pin2codeField, setPin2codeField] = useState("");
    const [showPin2codeField, setShowPin2codeField] = useState(false);

    const [hasThreeEqual, setHasThreeEqual] = useState(false);
    const [hasThreeConsecutive, setHasThreeConsecutive] = useState(false);
    const [pinCodeMatchError, setPinCodeMatchError] = useState(false);

    const [serialNumberField, setSerialNumberField] = useState("");
    const [otpCodeField, setOtpCodeField] = useState("");



    const [, setEsiaTokenOmegaReferrer] = useLocalStorage('esiaTokenOmegaReferrer', false);
    const [, setSberIdTokenOmegaReferrer] = useLocalStorage('sberIdTokenOmegaReferrer', false);

    const [isEsiaTokenReferrer, setEsiaTokenSigmaReferrer] = useLocalStorage('esiaTokenSigmaReferrer', false);
    const [isSberIdTokenReferrer, setSberIdTokenSigmaReferrer] = useLocalStorage('sberIdTokenSigmaReferrer', false);


    const [multiFactorErrorMessage, setMultiFactorErrorMessage] = useState("");
    const [esiaLoad, setEsiaLoad] = useState(false);
    const [sberIdLoad, setSberIdLoad] = useState(false);
    const [alertEsia, setAlertEsia] = useState(false);

    const [imageSnackBar1, setImageSnackBar1] = useState(false);
    const [imageSnackBar2, setImageSnackBar2] = useState(false);

    const multiFactorEnabled = userRole?.result.ui_settings.multifactorEnabled;

    const conditionSavingPin = (!hasThreeConsecutive && !hasThreeEqual && pin1codeField.length === 4 && pin2codeField.length === 4);
    const conditionActivateToken = (serialNumberField.length === 10 && otpCodeField.length === 6);


    const clearReferrer = () => {
        setSberIdTokenOmegaReferrer(false)
        setEsiaTokenOmegaReferrer(false)
        setEsiaTokenSigmaReferrer(false)
        setSberIdTokenSigmaReferrer(false)
    }


    const handleClickSberId = async () => {

        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Регистрация токена',
                eventAction: 'клик на кнопку  "Войти по Сбер ID"',
                value: ''
            }
        )
        //
        clearReferrer()
        setSberIdTokenSigmaReferrer(true)
        setSberIdLoad(true)
        const response = await getMultiFactorUrl('sber-id')
        if ("data" in response) {
            const {url} = response.data.result
            const a = document.createElement('a');
            a.href = url;
            a.click();
        }
        setSberIdLoad(false)
    }

    const handleClickEsia = async () => {

        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Регистрация токена',
                eventAction: 'клик на кнопку  "Войти через Госуслуги"',
                value: ''
            }
        )
        //
        clearReferrer()
        setEsiaTokenSigmaReferrer(true)
        setEsiaLoad(true)
        const response = await getMultiFactorUrl('esia')
        if ("data" in response) {
            const {url} = response.data.result;
            const a = document.createElement('a');
            a.href = url;
            a.click();
        }
        setEsiaLoad(false)
    }

    function hasThreeEqualNeighbors(arr: number[]): boolean {
        for (let i = 0; i < arr.length - 2; ++i) {
            if (arr[i] === arr[i + 1] && arr[i] === arr[i + 2]) {
                return true;
            }
        }
        return false;
    }

    function hasThreeConsecutiveOrderedNumbers(arr: number[]): boolean {

        for (let i = 0; i < arr.length - 2; ++i) {
            if (arr[i] === arr[i + 1] - 1 && arr[i + 1] + 1 === arr[i + 2]) {
                return true;
            }
            if (arr[i] - 1 === arr[i + 1] && arr[i + 1] === arr[i + 2] + 1) {
                return true;
            }
            if (arr[i] === 8 && arr[i + 1] === 9 && arr[i + 2] === 0) {
                return true
            }
            if (arr[i] === 0 && arr[i + 1] === 9 && arr[i + 2] === 8) {
                return true
            }
        }
        return false;
    }

    const checkPinCode = (e: ChangeEvent<HTMLInputElement>) => {


        let str = e.target.value.replace(/[^0-9]/g, '')

        if (str.length < 5) {
            setHasThreeEqual(false)
            setHasThreeConsecutive(false)
            setPinCodeMatchError(false)

            setPin1codeField(str)

            let numStr: Array<number> = []

            for (let char of str) {
                numStr.push(Number(char))
            }


            setHasThreeEqual(hasThreeEqualNeighbors(numStr))
            setHasThreeConsecutive(hasThreeConsecutiveOrderedNumbers(numStr))
        }
    }

    const handleClickSavePinCode = () => {
        if (pin1codeField === pin2codeField) {
            setStep2Passed(true)
        } else {
            setPinCodeMatchError(true)
        }
    }

    const handleClickActivateToken = async () => {

        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Регистрация токена',
                eventAction: 'клик на кнопку Активировать',
                value: ''
            }
        )
        //
        const res = await fetchActivateToken({
            pin: pin1codeField,
            otp: otpCodeField,
            serial: serialNumberField
        })

        if ("data" in res && res.data.status === "ok") {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Регистрация токена',
                    eventAction: 'клик на кнопку Активировать/успешная регистрация',
                    value: ''
                }
            )
            //
            setTimeout(() => {
                handleCloseSidePanel()
                clearSidePanel()
            }, 1500)
        }
    }


    const clearSidePanel = () => {
        multiFactorVerifyReset()
        setPin1codeField('')
        setPin2codeField('')
        setOtpCodeField('')
        setSerialNumberField('')
    }


    useEffect(() => {
        if (activateTokenError) {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Регистрация токена',
                    eventAction: 'клик на кнопку Активировать/неуспешная регистрация',
                    value: `${activateTokenError?.code}: ${activateTokenError?.message}`,
                }
            )
            //
        }
    }, [activateTokenError])


    const onMount = async () => {
        if (searchParams.toString() && (isEsiaTokenReferrer || isSberIdTokenReferrer) && multiFactorEnabled) {
            if (searchParams.toString() === ErrorMultifactorServer) {
                handleOpenSidePanel();
                setMultiFactorErrorMessage("Сервер временно недоступен, попробуйте позднее");
                navigate(routesPath.devices);
            } else {
                if (isEsiaTokenReferrer) {
                    setEsiaLoad(true)
                    alertEsiaAutoOpen.current = setTimeout(() => {
                        setAlertEsia(true)
                    }, 35000)
                }
                if (isSberIdTokenReferrer) {
                    setSberIdLoad(true)
                }
                // Открываем сайдпанель
                handleOpenSidePanel();

                // читаем квери парметры и делаем мультифакторную проверку
                const params = Object.fromEntries(searchParams.entries());
                navigate(routesPath.devices)
                await multiFactorVerify(params)
                clearTimeout(alertEsiaAutoOpen.current);
                setAlertEsia(false)
                setEsiaLoad(false)
                setSberIdLoad(false)
            }
            clearReferrer()
        }

    }


    useEffectOnce(() => {
        onMount()
    })

    return (
        <>
            <SidePanel open={openSidePanel}
                       onOpen={handleOpenSidePanel}
                       onClose={handleCloseSidePanel}
                       header={"Зарегистрировать токен"}
            >
                {
                    multiFactorEnabled && <div className={style.step}>
                        <Typography variant={"h5"} className={style.mb16}>
                            1. Подтвердите личность
                        </Typography>
                        {
                            multiFactorVerifyData?.result?.verified ?
                                <SuccessAlert>Личность подтверждена</SuccessAlert>
                                : (<>
                                    <Typography variant={"body1"} className={style.mb20}>
                                        Нам нужно убедиться, что вы сотрудник Банка, и устройство
                                        принадлежит вам.
                                    </Typography>
                                    <Typography variant={"body1"} className={style.mb16}>
                                        Сверим ваш Сбер ID или номер СНИЛС в Госуслугах. Не храним эти данные и не забираем
                                        остальную информацию из систем.
                                    </Typography>
                                    <Button variant={"contained"}
                                            startIcon={<SberIdIcon/>}
                                            onClick={handleClickSberId}
                                            loading={sberIdLoad}
                                            disabled={esiaLoad}
                                            fullWidth
                                            className={style.mb20}
                                    >
                                        <Typography variant={"h6"}>Войти по Сбер ID</Typography>
                                    </Button>
                                    <Button variant={"contained"}
                                            startIcon={<GosUslugiIcon/>}
                                            loading={esiaLoad}
                                            disabled={sberIdLoad}
                                            onClick={handleClickEsia}
                                            fullWidth
                                    >
                                        <Typography variant={"h6"}>Войти через Госуслуги</Typography>
                                    </Button>
                                </>)
                        }
                    </div>
                }

                {(multiFactorErrorMessage || multiFactorVerifyError?.message) &&
                    <div className={style.bottomAlert}>
                        <Alert severity={"error"}>{multiFactorErrorMessage || multiFactorVerifyError?.message}</Alert>
                    </div>
                }
                {
                    (multiFactorVerifyData?.result?.verified || !multiFactorEnabled) && <div className={style.step}>
                        <Typography variant={"h5"} className={style.mb16}>
                            {multiFactorEnabled ? "2" : "1"}. Придумайте пин-код
                        </Typography>
                        {
                            step2Passed ? <SuccessAlert>Пин-код установлен</SuccessAlert>
                                : <>
                                    <Typography variant={"body1"} className={style.mb16}>
                                        Вы будете вводить его вместе с ОТР-кодом для входа в АС — запомните его.
                                    </Typography>
                                    <TextField placeholder={"1487"}
                                               type={showPin1codeField ? "default" : "password"}
                                               value={pin1codeField}
                                               onChange={checkPinCode}
                                               customCounter={`${pin1codeField.length}/4`}
                                               label={"Пин-код"}
                                               required
                                               error={hasThreeEqual || hasThreeConsecutive || pinCodeMatchError}
                                               style={{marginBottom: 0}}
                                               endIcon={showPin1codeField ? <IconButton
                                                       size={"small"}
                                                       onClick={() => setShowPin1codeField(false)}
                                                   >
                                                       <EyeIcon color="primary"/>
                                                   </IconButton>
                                                   : <IconButton
                                                       size={"small"}
                                                       onClick={() => setShowPin1codeField(true)}
                                                   >
                                                       <EyeOffIcon color="primary"/>
                                                   </IconButton>
                                               }
                                    />
                                    <div className={style.pinHelperText}>
                                        <span className={hasThreeConsecutive ? style.error : style.success}>
                                            <span className={style.icon}>{hasThreeConsecutive ? <CloseCircleIcon/> :
                                                <DoneInCircleLineIcon/>}</span>
                                            Не используйте последовательности
                                        </span>
                                        <span className={hasThreeEqual ? style.error : style.success}>
                                            <span className={style.icon}>{hasThreeEqual ? <CloseCircleIcon/> :
                                                <DoneInCircleLineIcon/>}</span>
                                            Не используйте повторы
                                        </span>
                                    </div>
                                    <TextField placeholder={"1487"}
                                               value={pin2codeField}
                                               type={showPin2codeField ? "default" : "password"}
                                               onChange={(e) => {
                                                   setPinCodeMatchError(false)
                                                   let newVal = e.target.value.replace(/[^0-9]{0,4}$/g, '')
                                                   if (newVal.length < 5) {
                                                       setPin2codeField(newVal)
                                                   }
                                               }}
                                               customCounter={`${pin2codeField.length}/4`}
                                               label={"Повторите для проверки"}
                                               required
                                               error={pinCodeMatchError}
                                               helperText={pinCodeMatchError && "Пин-коды не совпадают"}
                                               onKeyDown={(e) => {
                                                   if (e.key === "Enter" && conditionSavingPin) {
                                                       handleClickSavePinCode()
                                                   }
                                               }}
                                               endIcon={showPin2codeField ? <IconButton
                                                       size={"small"}
                                                       onClick={() => setShowPin2codeField(false)}
                                                   >
                                                       <EyeIcon color="primary"/>
                                                   </IconButton>
                                                   : <IconButton
                                                       size={"small"}
                                                       onClick={() => setShowPin2codeField(true)}
                                                   >
                                                       <EyeOffIcon color="primary"/>
                                                   </IconButton>
                                               }
                                    />
                                    <Button color={"primary"}
                                            style={{display: "block"}}
                                            disabled={!conditionSavingPin}
                                            onClick={handleClickSavePinCode}
                                            className={style.mobileBtn}
                                    >
                                        Установить
                                    </Button>
                                </>
                        }

                    </div>
                }
                {
                    (multiFactorVerifyData?.result?.verified || !multiFactorEnabled) && step2Passed &&
                    <div className={style.step}>
                        <Typography variant={"h5"} className={style.mb16}>
                            {multiFactorEnabled ? "3" : "2"}. Активируйте токен
                        </Typography>
                        {
                            activateTokenData?.result ? <SuccessAlert>Токен активирован</SuccessAlert>
                                : <>
                                    <Typography variant={"body1"} className={style.mb16}>
                                        Чтобы определить, какой именно токен ваш, введите его серийный номер и ОТР-код
                                    </Typography>
                                    <div className={style.field}>
                                        <TextField placeholder={"1234567890"}
                                                   value={serialNumberField}
                                                   onChange={(e) => {
                                                       let newVal = e.target.value.replace(/[^0-9]/g, '')
                                                       if (newVal.length < 11)
                                                           setSerialNumberField(newVal)
                                                   }}
                                                   label={"Серийный номер"}
                                                   customCounter={`${serialNumberField.length}/10`}
                                                   required
                                        />
                                        <Typography variant={"body2"}>
                                            <span className="green-link" onClick={() => setImageSnackBar1(true)}>Cерийный номер</span>
                                            &nbsp;— 10 цифр на обороте токена
                                        </Typography>

                                    </div>
                                    <div className={style.field}>
                                        <TextField placeholder={"567834"}
                                                   value={otpCodeField}
                                                   onChange={(e) => {
                                                       let newVal = e.target.value.replace(/[^0-9]/g, '')
                                                       if (newVal.length < 7)
                                                           setOtpCodeField(newVal)
                                                   }}
                                                   label={"OTP-код"}
                                                   onKeyDown={(e) => {
                                                       if (e.key === "Enter" && conditionActivateToken) {
                                                           handleClickActivateToken()
                                                       }
                                                   }}
                                                   customCounter={`${otpCodeField.length}/6`}
                                                   required
                                        />
                                        <Typography variant={"body2"}>
                                            Вводите, когда&nbsp;
                                            <span className="green-link" onClick={() => setImageSnackBar2(true)}>на шкале больше 2 делений</span>
                                        </Typography>
                                    </div>
                                    <Button color={"primary"}
                                            className={style.mobileBtn}
                                            disabled={!conditionActivateToken}
                                            loading={loadingActivateToken}
                                            onClick={handleClickActivateToken}>
                                        Активировать
                                    </Button>
                                </>
                        }
                    </div>
                }
                {activateTokenError?.message &&
                    <div className={style.bottomAlert}>
                        <Alert severity={"error"}>{activateTokenError?.message}</Alert>
                    </div>
                }
                <Snackbar
                    placement={"bottom"}
                    hideIcon
                    open={imageSnackBar1}
                    iconButtonClose
                    transitionDuration={300}
                    TransitionProps={{}}
                    onClose={() => {
                        setImageSnackBar1(false)
                    }}
                    message={<img src={tokenBackImg} alt="tokenBackImg"/>}
                />
                <Snackbar
                    placement={"bottom"}
                    hideIcon
                    open={imageSnackBar2}
                    iconButtonClose
                    transitionDuration={300}
                    TransitionProps={{}}
                    onClose={() => {
                        setImageSnackBar2(false)
                    }}
                    message={<img src={tokenFrontImg} alt="tokenFrontImg"/>}
                />
                <Snackbar
                    placement={"top"}
                    open={alertEsia}
                    iconButtonClose
                    transitionDuration={300}
                    TransitionProps={{}}
                    autoHideDuration={7000}
                    onClose={() => {
                        setAlertEsia(false)
                    }}
                    message="Загружаем данные из Госуслуг, операция может занять несколько минут"
                />
            </SidePanel>
        </>
    );
};

export default HardwareTokenSigma;
