import React, {FunctionComponent, useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularLoader,
    Typography,
    Modal
} from "@sber-friend/flamingo-core";
import style from "./HardwareToken.module.scss";
import AppTable from '../../../components/AppTable/AppTable';
import {
    useDeleteHwpTokenMutation,
    useGetHwpTokenListQuery,
} from "../../../api/devices";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {
    ExternalLinkIcon,
    TrashIcon
} from '@sber-friend/flamingo-icons';
import Snackbar from '@sber-friend/flamingo-core/Snackbar';
import HardwareTokenSigma from "./HardwareTokenSigma";
import NotFoundImage from "../../../assets/img/Commons/PageNotFound.svg";
import HardwareTokenOmega from "./HardwareTokenOmega";


interface OwnProps {
}

type Props = OwnProps;

const HardwareToken: FunctionComponent<Props> = (props) => {

    // --- запросы


    const {
        data: tokenList,
        error: tokenListError,
        isFetching: tokenListLoading,
        refetch: refetchTokenList
    } = useGetHwpTokenListQuery(undefined)

    const [fetchDeleteHwpToken, {
        isLoading: deleteTokenIsLoading,
        error: deleteTokenError,
        reset: deleteTokenReset
    }] = useDeleteHwpTokenMutation()


    // ---

    const [confirmModal, setConfirmModal] = useState(false);


    const [currentDeleteToken, setCurrentDeleteToken] = useState("");


    const [openSidePanelSigma, setOpenSidePanelSigma] = useState(false);
    const [openSidePanelOmega, setOpenSidePanelOmega] = useState(false);


    const handleClickInstruction = () => {
        window.open("https://otp.sberbank.ru/instructions/token_registration.pdf", "_blank")
    }

    const deleteToken = async () => {
        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Регистрация токена',
                eventAction: 'клик на кнопку Удалить',
                value: ''
            }
        )
        //
        const res = await fetchDeleteHwpToken({serial: currentDeleteToken})
        if ("data" in res && res.data?.status === "ok") {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Регистрация токена',
                    eventAction: 'клик на кнопку Удалить/успешное удаление',
                    value: ''
                }
            )
            //
        }
        setConfirmModal(false)
    }

    const handleClickDeleteToken = (currentToken: string) => () => {
        setConfirmModal(true)
        setCurrentDeleteToken(currentToken)
    }


    const handleOpenSidePanelSigma = () => {
        setOpenSidePanelSigma(true)
    }

    const handleCloseSidePanelSigma = () => {
        setOpenSidePanelSigma(false)
    }

    const handleOpenSidePanelOmega = () => {
        setOpenSidePanelOmega(true)
    }

    const handleCloseSidePanelOmega = () => {
        setOpenSidePanelOmega(false)
    }

    const onCloseConfirmModal = () => {
        if (!deleteTokenIsLoading) {
            setConfirmModal(false)
        }
    }


    useEffect(() => {
        if (deleteTokenError) {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Регистрация токена',
                    eventAction: 'клик на кнопку Удалить/неуспешное удаление',
                    value: `${deleteTokenError?.code}: ${deleteTokenError?.message}`,
                }
            )
            //
        }
    }, [deleteTokenError])


    const column = {
        name: "Устройство",
        serialNumber: "Серийный номер",
        actions: "",
    }

    const list = tokenList ? tokenList.result.serial.map(serialNumber => (
        {
            name: "Токен",
            serialNumber,
            actions: <Button className={style.textSecondary} variant={"text"} startIcon={<TrashIcon/>}
                             onClick={handleClickDeleteToken(serialNumber)}>
                <Typography variant={"body2"} className={style.btnText}>Удалить</Typography>
            </Button>,
        }
    )) : []


    return (
        <>
            {
                tokenListLoading ? <div style={{height: 400}} className={"center"}>
                        <CircularLoader size={"large"}/>
                    </div>
                    : (list.length ? <div className={style.tableWrap}>
                                <div className={style.table}>
                                    <Button onClick={handleOpenSidePanelOmega} className={style.activateOmega}>
                                        Зарегистрировать в Omega
                                    </Button>
                                    <AppTable column={column} rows={list}/>
                                </div>

                                <div className={style.infoBoard}>
                                    {/*<a href="/" className={"green-link"}>Инструкция по настройке токена <ExternalLinkIcon/></a>*/}
                                    <Alert severity={"info"}>
                                        Удалите токен, если:
                                        <ul>
                                            <li>Забыли пин-код</li>
                                            <li>Сменили токен: в Sigma он может быть только один</li>
                                            <li>Токен выдаёт неправильные ОТР-коды</li>
                                        </ul>
                                    </Alert>
                                </div>
                                <Snackbar
                                    placement={"top"}
                                    variant={"error"}
                                    open={!!deleteTokenError?.message}
                                    iconButtonClose
                                    transitionDuration={300}
                                    TransitionProps={{}}
                                    onClose={deleteTokenReset}
                                    autoHideDuration={7000}
                                    message={deleteTokenError?.message}
                                />
                            </div>
                            : (tokenListError ? <div className={style.tableWrap}>
                                        <div style={{flex: "1 1 auto"}}>
                                            <Alert severity={"error"}
                                                   style={{marginBottom: 16}}>{tokenListError.message}</Alert>
                                            <Button
                                                className={style.refetchTokenListBtn}
                                                onClick={refetchTokenList}
                                                color={"primary"}
                                            >
                                                Попробовать снова
                                            </Button>
                                        </div>

                                        <div className={style.infoBoard}>
                                            {/*<a href="/" className={"green-link"}>Инструкция по настройке*/}
                                            {/*    токена <ExternalLinkIcon/></a>*/}
                                            <Alert severity={"info"}>
                                                <Typography variant={"body2"} color={"textSecondary"}>
                                                    Удалите токен, если:
                                                    <ul>
                                                        <li>Забыли пин-код</li>
                                                        <li>Сменили токен: в Sigma он может быть только один</li>
                                                        <li>Токен выдаёт неправильные ОТР-коды</li>
                                                    </ul>
                                                </Typography>
                                            </Alert>
                                        </div>
                                    </div>
                                    : <div className={style.emptyList}>
                                        <img src={NotFoundImage} alt="notFound"/>
                                        <div>
                                            <Typography variant={"h5"} style={{marginBottom: 8}}>
                                                Зарегистрируйте токен
                                            </Typography>
                                            <Typography variant={"body2"} className={style.text} color={"textSecondary"}>
                                                Если у вас его нет, согласуйте получение с руководителем и&nbsp;
                                                <a href="https://sberfriend.sbrf.ru/sberfriend/#/interaction/new?elementId=24599"
                                                   target={"_blank"}
                                                   className="green-link bold"
                                                   rel="noreferrer"
                                                >
                                                    заявка в Друге
                                                </a>.
                                            </Typography>
                                            <div className={style.buttonsWrap}>
                                                <Button
                                                    onClick={handleOpenSidePanelSigma}
                                                    color={"primary"}
                                                >
                                                    В Sigma
                                                </Button>
                                                <Button
                                                    onClick={handleOpenSidePanelOmega}
                                                >
                                                    В Omega
                                                </Button>
                                                <Typography onClick={handleClickInstruction} variant={"h6"}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                cursor: "pointer"
                                                            }}>
                                                    <ExternalLinkIcon fontSize={"small"}/>&nbsp;Открыть инструкцию
                                                </Typography>
                                            </div>
                                        </div>

                                    </div>
                            )
                    )
            }
            <HardwareTokenSigma openSidePanel={openSidePanelSigma}
                                handleOpenSidePanel={handleOpenSidePanelSigma}
                                handleCloseSidePanel={handleCloseSidePanelSigma}
            />
            <HardwareTokenOmega openSidePanel={openSidePanelOmega}
                                handleOpenSidePanel={handleOpenSidePanelOmega}
                                handleCloseSidePanel={handleCloseSidePanelOmega}
            />
            <Modal
                width={"449px"}
                open={confirmModal}
                onClose={onCloseConfirmModal}
                title={"Удаление токена"}
                className={style.confirmModalWrap}
                actionPrimary={<Button onClick={deleteToken} loading={deleteTokenIsLoading}>Удалить</Button>}
                actionSecondary={<Button onClick={onCloseConfirmModal} disabled={deleteTokenIsLoading}>Отмена</Button>}
            >
                <Typography variant="body1">После удаления вы сможете зарегистрировать его снова</Typography>
            </Modal>
        </>
    );
};

export default HardwareToken;
